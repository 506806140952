<template>
  <div style="padding: 15px;"
  >
    <el-row class="content_row" :gutter="15"
    >
      <el-col :md="24" style="margin-bottom: 15px;">
        <el-col :md="14">
          <el-select v-model="query.orderType" placeholder="排序规则" @change="orderSearch">
            <el-option
            label="时间"
            value="date"
            >
            </el-option>
            <el-option
            label="点赞"
            value="praise"
            >
            </el-option>
            <el-option
            label="默认(混合排序)"
            :value="null"
            :checked="true"
            >
            </el-option>
          </el-select>
          <Sort @change="sortSearch"></Sort>
        </el-col>
        <el-col :md="{span:10}">
          <InputSearchUi label="搜索"
           style="float: right;"
          @searchCond="searchCond"
          ></InputSearchUi>
        </el-col>
      </el-col>
      <el-col
      v-for="blogListItem in blogList"
      :md="{span:6}"
      :xs="{span:24,offset:0}"
      >
      <div v-for="blogItem in blogListItem.blogListItem">
        <transition name="el-fade-in" :appear="true">
         <div @click="link_to('/details',blogItem.id,$event)">
          <el-row>
            <el-col
            :xs="{span:24}"
            :md="{span:24}"
            >
             <blogCard style="margin-bottom: 15px;" :blog="blogItem"></blogCard>
            </el-col>
          </el-row>
          </div>
          </transition>
        </div>
      </el-col>

    </el-row>

    <div
    style="height: 100px;float:left;width: 100%;">
        <span style="display: none;">{{disabled}}</span>
        <h4 v-if="noMore && !loading"style="color:#BABE99;text-align: center;">没有更多了哦！</h4>
        <div v-loading="loading" style="margin-top: 20px;">
        </div>
    </div>
    <div
    style="height: 450px;"
    v-if="loading || noMore"
    >
    </div>
  </div>
</template>
<script>
import InputSearchUi from '@/components/ui/InputSearchUi'
import {getUserApi} from '@/api/user'
import {getArticelApi} from '@/api/article'
import blogCard from '@/components/blogCard.vue'
export default{
  name:'userBlog',
  components: {
    blogCard,
    InputSearchUi
  },
  created() {
    this.load()
  },
  data(){
    return{
      dataList:[],
      totalPage:undefined,
      currentPage:undefined,
      totalNumber:undefined,
      loading:true,
      blogList:[
        {
          blogListItem:[],
        },{
          blogListItem:[],
        },{
          blogListItem:[],
        },{
          blogListItem:[],
        }
      ],
      blogListAll:[],
      query:{
        pageNum:1,
        pageSize:12,
        creator:this.$route.query.username,

      },
    }
  },
  computed:{
    noMore() {
        return this.totalNumber < this.query.pageSize || this.totalPage===this.currentPage;
    },
    disabled() {
      this.$emit("changeDisabled",this.loading || this.noMore)
      return this.loading || this.noMore
    },
  },
  methods:{
    orderSearch(){
      this.restBlogList()
      this.query.pageNum=1
      this.query.pageSize=12
      this.load()
    },
    sortSearch(sort){
      this.restBlogList()
      this.query.pageNum=1
      this.query.pageSize=12
      this.query.sort=sort
      this.load()
    },
    link_to(path,blogId,event){
        this.$router.push({path: path, query: {blogId:blogId}})
    },
    load() {
        this.loading=true
        this.getArticel()
    },
    restBlogList(){
      this.blogList=[
        {
          blogListItem:[],
        },{
          blogListItem:[],
        },{
          blogListItem:[],
        },{
          blogListItem:[],
        }
      ]
    },
    searchCond(condition){
      this.restBlogList()
      this.query.pageNum=1
      this.query.pageSize=12
      this.query.condition=condition
      this.load()
    },
    async getArticel(){
     const res = await getArticelApi(this.query)
     const blogListAll=res.data.data.list;
     this.totalNumber=res.data.data.list.length;
     let  i=0;
     for( i=0;i<blogListAll.length;++i){
       this.blogList[i%4].blogListItem.push(blogListAll[i]);
     }
     this.loading=false;
     this.query.pageNum++;
     this.totalPage=res.data.data.pages
     this.currentPage=res.data.data.pageNum
    },

  }
}
</script>

<style scoped>
  .content_row{
    float: left;
    width: 100%;
  }
</style>
